import {Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'
import {ADMIN_HOME_PATH, LOGIN_PATH} from './application/types'
import {LoginComponent} from './components/login/login.component'

export const routes: Routes = [
  {
    path: ADMIN_HOME_PATH,
    loadComponent: () => import('./admin/home/home.component').then(m => m.HomeComponent),
    canActivate: [authGuard]
  },
  {
    path: LOGIN_PATH,
    component: LoginComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_PATH
  }
]
