import {Component} from '@angular/core'
import {BankIdComponent, TMode} from '@sparbanken-syd/sparbanken-syd-bankid'
import {environment} from '../../../environments/environment'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'spb-login',
  standalone: true,
  templateUrl: './login.component.html',
  imports: [
    BankIdComponent
  ],
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  public bankIdUrl = environment.authServiceUrl
  public domain = environment.domain
  public mode: TMode = 'login'
  constructor(
    public configService: ConfigService
  ) {
  }
}
